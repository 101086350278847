/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { put } from 'redux-saga/effects';
import { ActionSaga } from 'src/types/saga.types';
import { addGiftCardSuccess } from './actions';
import { GiftCardBody } from './types';

export function* addGiftCardWorker(action: ActionSaga<GiftCardBody>) {
  if (!action.payload) {
    return;
  }

  yield put(addGiftCardSuccess(action.payload));
}
