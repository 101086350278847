/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  Category,
  CategoryAddBody,
  CategoryDeleteBody,
  CategoryItemPayload,
  CategoryListParams,
  CategoryUpdateBody,
} from './types';

const rootAPI = 'categories';

export const getCategoryListAPI = (payload?: CategoryListParams) => {
  return axiosQuery.get<ResponseStructure<Category[]>>(rootAPI, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getCategoryItemAPI = (payload?: CategoryItemPayload) => {
  return axiosQuery.get<ResponseStructure<Category>>(`${rootAPI}/${payload?.id}`);
};

export const addCategoryItemAPI = (payload?: CategoryAddBody) => {
  return axiosQuery.post<ResponseStructure<Category>>(rootAPI, payload, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const updateCategoryItemAPI = (payload?: CategoryUpdateBody) => {
  return axiosQuery.post<ResponseStructure<Category>>(
    `${rootAPI}/${payload?.id}`,
    {
      _method: 'PUT',
      ...payload,
    },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};

export const deleteCategoryItemAPI = (payload?: CategoryDeleteBody) => {
  return axiosQuery.delete<ResponseStructure<Category>>(`${rootAPI}/${payload?.id}`);
};
