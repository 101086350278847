/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, cancel, put, SagaReturnType } from 'redux-saga/effects';
import { ActionSaga } from 'src/types/saga.types';
import {
  addCategoryItemError,
  addCategoryItemSuccess,
  clearCategoryList,
  deleteCategoryItemError,
  deleteCategoryItemSuccess,
  getCategoryItemError,
  getCategoryItemSuccess,
  getCategoryListError,
  getCategoryListSuccess,
  setFilterCategoryList,
  updateCategoryItemError,
  updateCategoryItemSuccess,
} from './actions';
import {
  addCategoryItemAPI,
  deleteCategoryItemAPI,
  getCategoryItemAPI,
  getCategoryListAPI,
  updateCategoryItemAPI,
} from './apis';
import {
  CategoryAddBody,
  CategoryDeleteBody,
  CategoryFilterable,
  CategoryItemPayload,
  CategoryListParams,
  CategoryUpdateBody,
} from './types';

export function* getCategoryListWorker(action: ActionSaga<CategoryListParams>) {
  try {
    const { data }: SagaReturnType<typeof getCategoryListAPI> = yield call(
      getCategoryListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getCategoryListSuccess({
        categories: data.data,
        pagination: newPagination,
      })
    );
  } catch {
    yield put(getCategoryListError());
  }
}

export function* setFilterCategoryListWorker(action: ActionSaga<CategoryListParams>) {
  yield put(clearCategoryList());
  yield call(getCategoryListWorker, action);
}

export function* getCategoryItemWorker(action: ActionSaga<CategoryItemPayload>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getCategoryItemAPI> = yield call(
        getCategoryItemAPI,
        action.payload
      );
      yield put(getCategoryItemSuccess({ category: data.data }));
    }
  } catch {
    yield put(getCategoryItemError());
  }
}

export function* addCategoryItemWorker(action: ActionSaga<CategoryAddBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof addCategoryItemAPI> = yield call(
        addCategoryItemAPI,
        action.payload
      );
      yield put(addCategoryItemSuccess({ category: data.data }));
    }
  } catch (e) {
    yield put(addCategoryItemError());
  }
}

export function* addMultipleCategoryItemWorker(action: ActionSaga<CategoryAddBody>) {
  // for (let i = 10; i <= 500; i++) {
  //   try {
  //     if (action.payload) {
  //       const { data }: SagaReturnType<typeof addCategoryItemAPI> = yield call(addCategoryItemAPI, {
  //         name: action.payload.name + '_' + i,
  //       });
  //       yield put(addCategoryItemSuccess({ category: data.data }));
  //     }
  //   } catch {
  //     yield put(addCategoryItemError());
  //   }
  // }
}

export function* updateCategoryItemWorker(action: ActionSaga<CategoryUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateCategoryItemAPI> = yield call(
        updateCategoryItemAPI,
        action.payload
      );
      yield put(updateCategoryItemSuccess({ category: data.data }));
    }
  } catch {
    yield put(updateCategoryItemError());
  }
}

export function* deleteCategoryItemWorker(action: ActionSaga<CategoryDeleteBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteCategoryItemAPI> = yield call(
        deleteCategoryItemAPI,
        action.payload
      );
      yield put(deleteCategoryItemSuccess({ category: { ...data.data, id: action.payload.id } }));
    }
  } catch {
    yield put(deleteCategoryItemError());
  }
}

// logic CRUD flow
// link tham khảo: https://redux-saga.js.org/docs/advanced/NonBlockingCalls
