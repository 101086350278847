/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer } from '@reduxjs/toolkit';
import {
  addGiftCard,
  addGiftCardError,
  addGiftCardSuccess,
  closeModalGiftCard,
  showModalGiftCard,
} from './actions';
import { GiftCardReducer } from './types';

const initialState: GiftCardReducer = {};

const giftCardReducer = createReducer(initialState, (builder) => {
  // UI INTERACTIVE
  builder.addCase(showModalGiftCard, (state) => {
    state.ui = { ...state.ui, visible: true };
  });
  builder.addCase(closeModalGiftCard, (state) => {
    state.ui = { ...state.ui, visible: false };
  });
  // SERVICES INTERACTIVE
  builder.addCase(addGiftCard, (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(addGiftCardSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false, visible: false };
    state.giftCards = [payload].concat(state.giftCards || []);
  });
  builder.addCase(addGiftCardError, (state) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
});

export default giftCardReducer;
