/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  ADD_MULTIPLE_USER_ITEM,
  ADD_USER_ITEM,
  DELETE_USER_ITEM,
  GET_USER_ITEM,
  GET_USER_LIST,
  SET_FILTER_USER_LIST,
  UPDATE_USER_ITEM,
} from './actions';
import {
  addMultipleUserItemWorker,
  addUserItemWorker,
  deleteUserItemWorker,
  getUserItemWorker,
  getUserListWorker,
  setFilterUserListWorker,
  updateUserItemWorker,
} from './workers';

export function* userSaga() {
  yield takeLatest(GET_USER_LIST, getUserListWorker);
  yield takeLatest(GET_USER_ITEM, getUserItemWorker);
  yield takeLatest(SET_FILTER_USER_LIST, setFilterUserListWorker);
  yield takeLatest(ADD_USER_ITEM, addUserItemWorker);
  yield takeLatest(ADD_MULTIPLE_USER_ITEM, addMultipleUserItemWorker);
  yield takeLatest(UPDATE_USER_ITEM, updateUserItemWorker);
  yield takeLatest(DELETE_USER_ITEM, deleteUserItemWorker);
}
