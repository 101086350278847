/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import {
  addUserItemError,
  addUserItemSuccess,
  clearUserList,
  deleteUserItemError,
  deleteUserItemSuccess,
  getUserItemError,
  getUserItemSuccess,
  getUserListError,
  getUserListSuccess,
  updateUserItemError,
  updateUserItemSuccess,
} from './actions';
import {
  addUserItemAPI,
  deleteUserItemAPI,
  getUserItemAPI,
  getUserListAPI,
  updateUserItemAPI,
} from './apis';
import {
  UserAddBody,
  UserDeleteBody,
  UserItemPayload,
  UserListParams,
  UserUpdateBody,
} from './types';

export function* getUserListWorker(action: ActionSaga<UserListParams>) {
  try {
    const { data }: SagaReturnType<typeof getUserListAPI> = yield call(
      getUserListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getUserListSuccess({
        users: data.data,
        pagination: newPagination,
      })
    );
  } catch {
    yield put(getUserListError());
    showErrorToast();
  }
}

export function* setFilterUserListWorker(action: ActionSaga<UserListParams>) {
  yield put(clearUserList());
  yield call(getUserListWorker, action);
}

export function* getUserItemWorker(action: ActionSaga<UserItemPayload>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getUserItemAPI> = yield call(
        getUserItemAPI,
        action.payload
      );
      yield put(getUserItemSuccess({ user: data.data }));
    }
  } catch {
    yield put(getUserItemError());
    showErrorToast();
  }
}

export function* addUserItemWorker(action: ActionSaga<UserAddBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof addUserItemAPI> = yield call(
        addUserItemAPI,
        action.payload
      );
      yield put(addUserItemSuccess({ user: data.data }));
      showSuccessToast();
    }
  } catch (e) {
    yield put(addUserItemError());
    showErrorToast();
  }
}

export function* addMultipleUserItemWorker(action: ActionSaga<UserAddBody>) {
  // for (let i = 10; i <= 500; i++) {
  //   try {
  //     if (action.payload) {
  //       const { data }: SagaReturnType<typeof addUserItemAPI> = yield call(addUserItemAPI, {
  //         name: action.payload.name + '_' + i,
  //       });
  //       yield put(addUserItemSuccess({ user: data.data }));
  //     }
  //   } catch {
  //     yield put(addUserItemError());
  //   }
  // }
}

export function* updateUserItemWorker(action: ActionSaga<UserUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateUserItemAPI> = yield call(
        updateUserItemAPI,
        action.payload
      );
      yield put(updateUserItemSuccess({ user: data.data }));
      showSuccessToast();
    }
  } catch {
    yield put(updateUserItemError());
    showErrorToast();
  }
}

export function* deleteUserItemWorker(action: ActionSaga<UserDeleteBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteUserItemAPI> = yield call(
        deleteUserItemAPI,
        action.payload
      );
      yield put(deleteUserItemSuccess({ user: { ...data.data, id: action.payload.id } }));
      showSuccessToast();
    }
  } catch {
    yield put(deleteUserItemError());
    showErrorToast();
  }
}

// logic CRUD flow
// link tham khảo: https://redux-saga.js.org/docs/advanced/NonBlockingCalls
