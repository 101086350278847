/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  ADD_MULTIPLE_INVOICE_ITEM_ITEM,
  ADD_INVOICE_ITEM_ITEM,
  DELETE_INVOICE_ITEM_ITEM,
  GET_INVOICE_ITEM_ITEM,
  GET_INVOICE_ITEM_LIST,
  SET_FILTER_INVOICE_ITEM_LIST,
  UPDATE_INVOICE_ITEM_ITEM,
} from './actions';
import {
  addInvoiceSubItemWorker,
  deleteInvoiceSubItemWorker,
  getInvoiceSubItemWorker,
  getInvoiceItemListWorker,
  setFilterInvoiceItemListWorker,
  updateInvoiceSubItemWorker,
} from './workers';

export function* invoiceItemSaga() {
  yield takeLatest(GET_INVOICE_ITEM_LIST, getInvoiceItemListWorker);
  yield takeLatest(GET_INVOICE_ITEM_ITEM, getInvoiceSubItemWorker);
  yield takeLatest(SET_FILTER_INVOICE_ITEM_LIST, setFilterInvoiceItemListWorker);
  yield takeLatest(ADD_INVOICE_ITEM_ITEM, addInvoiceSubItemWorker);
  yield takeLatest(UPDATE_INVOICE_ITEM_ITEM, updateInvoiceSubItemWorker);
  yield takeLatest(DELETE_INVOICE_ITEM_ITEM, deleteInvoiceSubItemWorker);
}
