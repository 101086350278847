/**
 * The event send to the store
 */

import { ActionSaga } from 'src/types/saga.types';
import {
  TruckAddBody,
  TruckDeleteBody,
  TruckFilterable,
  TruckItemPayload,
  TruckItemSuccessPayload,
  TruckListParams,
  TruckListSuccessPayload,
  TruckUpdateBody,
} from './types';

export const GET_TRUCK_LIST = 'GET_TRUCK_LIST';
export const GET_TRUCK_LIST_SUCCESS = 'GET_TRUCK_LIST_SUCCESS';
export const GET_TRUCK_LIST_ERROR = 'GET_TRUCK_LIST_ERROR';
export const SET_FILTER_TRUCK_LIST = 'SET_FILTER_TRUCK_LIST';
export const CLEAR_TRUCK_LIST = 'CLEAR_TRUCK_LIST';

export const GET_TRUCK_ITEM = 'GET_TRUCK_ITEM';
export const GET_TRUCK_ITEM_SUCCESS = 'GET_TRUCK_ITEM_SUCCESS';
export const GET_TRUCK_ITEM_ERROR = 'GET_TRUCK_ITEM_ERROR';

export const ADD_TRUCK_ITEM = 'ADD_TRUCK_ITEM';
export const ADD_MULTIPLE_TRUCK_ITEM = 'ADD_MULTIPLE_TRUCK_ITEM';
export const ADD_TRUCK_ITEM_SUCCESS = 'ADD_TRUCK_ITEM_SUCCESS';
export const ADD_TRUCK_ITEM_ERROR = 'ADD_TRUCK_ITEM_ERROR';

export const UPDATE_TRUCK_ITEM = 'UPDATE_TRUCK_ITEM';
export const UPDATE_TRUCK_ITEM_SUCCESS = 'UPDATE_TRUCK_ITEM_SUCCESS';
export const UPDATE_TRUCK_ITEM_ERROR = 'UPDATE_TRUCK_ITEM_ERROR';

export const DELETE_TRUCK_ITEM = 'DELETE_TRUCK_ITEM';
export const DELETE_TRUCK_ITEM_SUCCESS = 'DELETE_TRUCK_ITEM_SUCCESS';
export const DELETE_TRUCK_ITEM_ERROR = 'DELETE_TRUCK_ITEM_ERROR';

export const getTruckList = (payload?: TruckListParams): ActionSaga<TruckListParams> => ({
  type: GET_TRUCK_LIST,
  payload: payload ? { ...payload } : null,
});

export const getTruckListSuccess = (
  payload: TruckListSuccessPayload
): ActionSaga<TruckListSuccessPayload> => ({
  type: GET_TRUCK_LIST_SUCCESS,
  payload: { ...payload },
});

export const getTruckListError = (): ActionSaga => ({
  type: GET_TRUCK_LIST_ERROR,
});

export const setFilterTruckList = (payload?: TruckFilterable): ActionSaga<TruckListParams> => ({
  type: SET_FILTER_TRUCK_LIST,
  payload: { filterable: { ...payload } },
});

export const clearTruckList = (): ActionSaga<TruckListSuccessPayload> => ({
  type: CLEAR_TRUCK_LIST,
  payload: { trucks: [] },
});

export const getTruckItem = (payload: TruckItemPayload): ActionSaga<TruckItemPayload> => ({
  type: GET_TRUCK_ITEM,
  payload: { ...payload },
});

export const getTruckItemSuccess = (
  payload: TruckItemSuccessPayload
): ActionSaga<TruckItemSuccessPayload> => ({
  type: GET_TRUCK_ITEM_SUCCESS,
  payload: { ...payload },
});

export const getTruckItemError = (): ActionSaga => ({
  type: GET_TRUCK_ITEM_ERROR,
});

export const addTruckItem = (payload: TruckAddBody): ActionSaga<TruckAddBody> => ({
  type: ADD_TRUCK_ITEM,
  payload: { ...payload },
});

export const addMultipleTruckItem = (payload: TruckAddBody): ActionSaga<TruckAddBody> => ({
  type: ADD_MULTIPLE_TRUCK_ITEM,
  payload: { ...payload },
});

export const addTruckItemSuccess = (
  payload: TruckItemSuccessPayload
): ActionSaga<TruckItemSuccessPayload> => ({
  type: ADD_TRUCK_ITEM_SUCCESS,
  payload: { ...payload },
});

export const addTruckItemError = (): ActionSaga => ({
  type: ADD_TRUCK_ITEM_ERROR,
});

export const updateTruckItem = (payload: TruckUpdateBody): ActionSaga<TruckUpdateBody> => ({
  type: UPDATE_TRUCK_ITEM,
  payload: { ...payload },
});

export const updateTruckItemSuccess = (
  payload: TruckItemSuccessPayload
): ActionSaga<TruckItemSuccessPayload> => ({
  type: UPDATE_TRUCK_ITEM_SUCCESS,
  payload: { ...payload },
});

export const updateTruckItemError = (): ActionSaga => ({
  type: UPDATE_TRUCK_ITEM_ERROR,
});

export const deleteTruckItem = (payload: TruckDeleteBody): ActionSaga<TruckDeleteBody> => ({
  type: DELETE_TRUCK_ITEM,
  payload: { ...payload },
});

export const deleteTruckItemSuccess = (
  payload: TruckItemSuccessPayload
): ActionSaga<TruckItemSuccessPayload> => ({
  type: DELETE_TRUCK_ITEM_SUCCESS,
  payload: { ...payload },
});

export const deleteTruckItemError = (): ActionSaga => ({
  type: DELETE_TRUCK_ITEM_ERROR,
});
