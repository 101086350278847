import { takeLatest } from 'redux-saga/effects';
import {
  createTruckDelivery,
  createTruckDeliveryAndAddInventory,
  deleteTruckDelivery,
  getCurrentTruckDelivery,
  updateTruckDelivery,
} from './actions';
import {
  createTruckDeliveryAndAddInventoryWorker,
  createTruckDeliveryWorker,
  deleteTruckDeliveryWorker,
  getCurrentTruckDeliveryWorker,
  updateTruckDeliveryWorker,
} from './workers';

/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */
function* TruckDeliverySaga() {
  yield takeLatest(getCurrentTruckDelivery, getCurrentTruckDeliveryWorker);
  yield takeLatest(createTruckDelivery, createTruckDeliveryWorker);
  yield takeLatest(createTruckDeliveryAndAddInventory, createTruckDeliveryAndAddInventoryWorker);
  yield takeLatest(updateTruckDelivery, updateTruckDeliveryWorker);
  yield takeLatest(deleteTruckDelivery, deleteTruckDeliveryWorker);
}

export default TruckDeliverySaga;
