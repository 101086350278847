/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ADD_MULTIPLE_CATEGORY_ITEM,
  ADD_CATEGORY_ITEM,
  DELETE_CATEGORY_ITEM,
  GET_CATEGORY_ITEM,
  GET_CATEGORY_LIST,
  SET_FILTER_CATEGORY_LIST,
  UPDATE_CATEGORY_ITEM,
} from './actions';
import {
  addMultipleCategoryItemWorker,
  addCategoryItemWorker,
  deleteCategoryItemWorker,
  getCategoryItemWorker,
  getCategoryListWorker,
  setFilterCategoryListWorker,
  updateCategoryItemWorker,
} from './workers';

export function* categorySaga() {
  yield takeLatest(GET_CATEGORY_LIST, getCategoryListWorker);
  yield takeLatest(GET_CATEGORY_ITEM, getCategoryItemWorker);
  yield takeLatest(SET_FILTER_CATEGORY_LIST, setFilterCategoryListWorker);
  yield takeLatest(ADD_CATEGORY_ITEM, addCategoryItemWorker);
  yield takeLatest(ADD_MULTIPLE_CATEGORY_ITEM, addMultipleCategoryItemWorker);
  yield takeLatest(UPDATE_CATEGORY_ITEM, updateCategoryItemWorker);
  yield takeLatest(DELETE_CATEGORY_ITEM, deleteCategoryItemWorker);
}
