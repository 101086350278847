/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { LoadOut } from '../loadOut/types';
import { TruckCart } from '../truckCart/types';
import { LoadIn, LoadInInventoriesParams, UpdateLoadInField } from './types';

export const getLoadInInventories = createAction<LoadInInventoriesParams>(
  'load-in/getLoadInInventories'
);
export const getLoadInInventoriesSuccess = createAction<LoadIn[]>(
  'load-in/getLoadInInventories/success'
);
export const getLoadInInventoriesError = createAction('load-in/getLoadInInventories/error');

// Update load out
export const updateLoadOutExpand = createAction<LoadOut>('load-in/updateLoadOutExpand');

export const updateFieldLoadIn = createAction<UpdateLoadInField>('load-in/updateFieldLoadIn');

export const increaseLoadInTempReload = createAction('load-in/increaseLoadInTempReload');
