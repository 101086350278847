/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ReducerSaga } from 'src/types/saga.types';
import {
  ADD_CATEGORY_ITEM,
  ADD_CATEGORY_ITEM_ERROR,
  ADD_CATEGORY_ITEM_SUCCESS,
  CLEAR_CATEGORY_LIST,
  DELETE_CATEGORY_ITEM,
  DELETE_CATEGORY_ITEM_ERROR,
  DELETE_CATEGORY_ITEM_SUCCESS,
  GET_CATEGORY_ITEM,
  GET_CATEGORY_ITEM_ERROR,
  GET_CATEGORY_ITEM_SUCCESS,
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_ERROR,
  GET_CATEGORY_LIST_SUCCESS,
  SET_FILTER_CATEGORY_LIST,
  UPDATE_CATEGORY_ITEM,
  UPDATE_CATEGORY_ITEM_ERROR,
  UPDATE_CATEGORY_ITEM_SUCCESS,
} from './actions';
import { CategoryReducer } from './types';

const initialState: CategoryReducer = {
  categories: undefined,
};

const categoryReducer: ReducerSaga<CategoryReducer> = (
  state = initialState,
  payload
): CategoryReducer => {
  const { type, ...rest } = payload;
  switch (type) {
    case GET_CATEGORY_LIST:
    case GET_CATEGORY_ITEM:
    case ADD_CATEGORY_ITEM:
    case UPDATE_CATEGORY_ITEM:
    case DELETE_CATEGORY_ITEM:
      return { ...state, ...rest.payload, loading: true };

    case GET_CATEGORY_LIST_ERROR:
    case GET_CATEGORY_ITEM_ERROR:
    case ADD_CATEGORY_ITEM_ERROR:
    case UPDATE_CATEGORY_ITEM_ERROR:
    case DELETE_CATEGORY_ITEM_ERROR:
      showErrorToast();
      return { ...state, loading: false };

    case GET_CATEGORY_LIST_SUCCESS:
      const { isPagination } = rest.payload?.pagination || {};
      return {
        ...state,
        ...rest.payload,
        categories: isPagination
          ? (state.categories || [])?.concat(rest.payload?.categories || [])
          : rest.payload?.categories,
        loading: false,
      };
    case GET_CATEGORY_ITEM_SUCCESS:
      return { ...state, ...rest.payload, loading: false };
    case ADD_CATEGORY_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        categories: (rest.payload?.category ? [rest.payload?.category] : []).concat(
          state.categories || []
        ),
        loading: false,
      };
    case UPDATE_CATEGORY_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        categories: state.categories?.map((e) =>
          e.id === rest.payload?.category?.id ? { ...e, ...rest.payload.category } : e
        ),
        loading: false,
      };
    case DELETE_CATEGORY_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        categories: state.categories?.filter((e) => e.id !== rest.payload?.category?.id),
        loading: false,
      };

    case SET_FILTER_CATEGORY_LIST:
      return { ...state, loading: true, filterable: rest.payload?.filterable };
    case CLEAR_CATEGORY_LIST:
      return { ...state, categories: [], loading: true };

    default:
      return { ...state };
  }
};

export default categoryReducer;
