/**
 * The action send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { GiftCardBody } from './types';

// UI INTERACTIVE
export const showModalGiftCard = createAction('giftCard/showModal');
export const closeModalGiftCard = createAction('giftCard/closeModal');

// SERVICES INTERACTIVE
export const addGiftCard = createAction<GiftCardBody>('giftCard/addGiftCard');
export const addGiftCardSuccess = createAction<GiftCardBody>('giftCard/addGiftCard/success');
export const addGiftCardError = createAction('giftCard/addGiftCard/error');
